footer {
  color:   #6e6e6e;
  font-size: $font-size-sm;
  -webkit-font-smoothing: antialiased;

  .container {
    padding-top: 30px;

    > .footer-divider {
      clear:      both;
      width:      100%;
      border-top: 1px solid #ddd;
    }

    .piped-links {
      padding-left: 0px;

      > p {
        float:         left;
        padding-right: 10px;
      }

      > li {
        float:        none;
        display:      inline-block;
        margin-left:  .3em;
        padding-left: .3em;
        border-left:  1px solid #ccc;
      }

      > li.first {
        margin-left:  0;
        padding-left: 0;
        border-left:  none;
      }

      > .piped-links-short {
        display: initial !important;
      }

      > .piped-links-long {
        display: none !important;
      }

      @include media-breakpoint-up(sm) {
        > .piped-links-short {
          display: none !important;
        }

        > .piped-links-long {
          display: initial !important;
        }
      }
    }
  }
}