.curriculum-vitae {
  > .box {
    margin-bottom: 15px;

    #education {
      margin-top: 20px;
      position:   relative;
      padding:    1em 0;
      list-style: none;

      &:before {
        width:            2px;
        height:           100%;
        position:         absolute;
        left:             25px;
        top:              0;
        content:          ' ';
        display:          block;
        background-image: linear-gradient(var(--#{$prefix}secondary-bg), var(--#{$prefix}secondary-color), var(--#{$prefix}secondary-bg));

        @include media-breakpoint-down(lg) {
          left: 20px;
        }
      }

      > li {
        width:    100%;
        z-index:  2;
        position: relative;
        float:    left;

        > .year {
          width:       10%;
          background:  var(--#{$prefix}body-bg);
          padding:     10px;
          font-weight: 700;
          display:     inline-block;

          @include media-breakpoint-down(lg) {
            position: relative;
            transform: rotate(-90deg);
            float: inherit;
            padding: 0;
            bottom: -5px;
          }
        }

        > .description {
          width:         87%;
          display:       inline-block;
          background:    var(--#{$prefix}tertiary-color);
          margin-bottom: 10px;
          position:      relative;
          padding:       10px 10px 0 10px;
          border-bottom: 1px solid var(--#{$prefix}secondary-bg);

          &:after {
            content:  '';
            position: absolute;
            top:      15px;
            right:    0;
            left:     -16px;
            height:   0;
            width:    0;
            border:   solid transparent;

            border-right-color: var(--#{$prefix}tertiary-color);
            border-width:       8px;
            pointer-events:     none;
          }

          > h3 {
            margin:      0;
            padding:     0;
            font-weight: 700;
          }

          > p {
            margin-top: 5px;
            padding:    0;
          }
        }
      }
    }

    .job {
      margin-bottom: 25px;

      .where {
        font-weight: bold;
      }

      .year {
        opacity: 0.7;
      }

      .profession {
        font-weight: bold;
        margin-bottom: 10px;
      }

      .description {
        line-height: 1.5em;
      }
    }
  }

  .contact-item {
    width: 100%;
    float: left;

    > a {
      color: inherit;

      &:hover {
        color: inherit;
      }

      > .icon {
        padding:       10px;
        opacity:       0.2;

        > .fa {
          font-size: 1.6em;
        }
      }

      > .title {
          width:       'calc(100% - 55px)';
          font-weight: 700;
          opacity:     0.9;

          &.only {
              margin-top: 10px;
          }
      }

      > .description {
        width:       'calc(100% - 55px)';
        opacity:     0.7;
        line-height: 8px;
      }
    }
  }

  .skills {
    > .item-skills {
      height:           30px;
      color:          #fff;
      padding:          5px 10px;
      margin-bottom:    5px;
      white-space:      nowrap;
      overflow:         hidden;
      text-overflow:    ellipsis;
      font-weight:      600;
      background-image: linear-gradient(to right, var(--#{$prefix}secondary-color), var(--#{$prefix}secondary-bg));
    }

    > .skills-legend {
      margin-top: 20px;
      opacity:    0.8;

      &:before {
        width:         100%;
        height:        1px;
        position:      relative;
        content:       ' ';
        display:       block;
        margin-bottom: -13px;
        background:    var(--#{$prefix}secondary-color);
      }

      .legend {
        &:before {
          width:         1px;
          height:        12px;
          position:      relative;
          content:       ' ';
          display:       block;
          margin:        auto;
          margin-bottom: 2px;
          background:    var(--#{$prefix}secondary-color);
        }
      }

      > .legend-left {
        &.legend {
          &:before {
            margin-left:   0;
          }
        }

        &:nth-child(2) {
          > span {
            margin-left: -25px;
          }
        }
      }

      .legend-right {
        text-align: right;

        &.legend {
          &:before {
            margin-right: 0;
          }
        }

          &:nth-child(3) {
          > span {
            margin-right: -20px;
          }
        }
      }

      > div {
        width:   25%;
        display: inline-block;
        color:   #a9a9a9;
        float:   left;
      }
    }
  }

  #language-skills {
    > .skill {
      margin:         10px 0;
      padding-bottom: 10px;
      border-bottom:  1px solid var(--#{$prefix}secondary-color);

      > .icons {
        width:      111px;
        height:     14px;
        // background: url('@{resource-image-path}star-grey.png') no-repeat;

        > .icons-red {
          width:      80%;
          height:     14px;
          // background: url('@{resource-image-path}star-red.png') no-repeat;
        }
      }
    }
  }

  .hobby {
    color:         var(--#{$prefix}secondary-bg);
    display:       inline-block;
    margin-right:  5px;
    margin-bottom: 5px;
    padding:       5px 5px 5px 5px; // 5px 5px 5px 25px;
    background:    var(--#{$prefix}secondary-color);
    position:      relative;

    /*
    &:before {
      content:     '\f004';
      font-family: FontAwesome;
      font-style:  normal;
      font-weight: normal;
      position:    absolute;
      top:         6px;
      left:        7px;
    }
    */
  }

  .recommendations {
    .blockquote {
      font-size: $font-size-base;
    }
  }
}