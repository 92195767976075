.gallery-image {
  display:       table;
  box-shadow:    0px 0px 10px 0px var(--#{$prefix}secondary-color);
  border:        0;
  margin-bottom: 15px;
  width:         100%;

  &.vertical {
    margin-bottom: 15px !important;
    margin:  0 auto;
  }

  @include media-breakpoint-up(md) {
    &.vertical {
      width: 45%;
    }
  }

  .card-title, .card-text {
    text-shadow: 1px 1px 2px #242424;
  }
}

.image-caption {
  box-shadow:       0px 0px 10px 0px var(--#{$prefix}secondary-color);
  background-color: var(--#{$prefix}body-bg);
  // border:          1px solid var(--#{$prefix}tertiary-color);
  margin-top:       -16px;
  border-radius:    0px 0px 5px 5px;
  border-width:     0px 1px 1px 1px;
  padding:          10px;
  text-align:       justify;
  text-align-last:  center;
  white-space:      pre-line;

  &.vertical {
    margin:   0 auto;
    width:    100%;

    margin-bottom: 10px !important;
    margin-top:    -15px !important;
  }

  @include media-breakpoint-up(md) {
    &.vertical {
      width: 45%;
    }
  }
}

.gallery-video {
  display:       table;
  box-shadow:    0px 0px 10px 0px var(--#{$prefix}secondary-color);
  border:        0;
  margin-bottom: 15px;
  width:         100%;
}

.video-caption {
  box-shadow:       0px 0px 10px 0px var(--#{$prefix}secondary-color);
  background-color: var(--#{$prefix}body-bg);
  // border:          1px solid #ccc;
  margin-top:       -16px;
  border-radius:    0px 0px 5px 5px;
  border-width:     0px 1px 1px 1px;
  padding:          10px;
  text-align:       justify;
  text-align-last:  center;
  white-space:      pre-line;
}

.album-cover {
  aspect-ratio: 2048 / 1356;
}