header {
  margin-top: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;

  .container {
    max-width: max-content;
  }
}