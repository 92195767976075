.navbar {
  border-radius: 4px;
  background:    linear-gradient(to bottom, #8d8d8d 0%, #717171 50%, #5e5e5e 51%, #787878 100%);
  border:        1px solid #515151;
  box-shadow:    0 2px 3px 0px #afafaf;
  z-index:       1000;
  border-width:  0 0 1px;
  padding:       0;

  .container-fluid {
    padding: 0;

    .navbar-brand {
      /* the same as for nav-link */
      color:         white;
      text-decoration: none;
      text-align:      center;
      text-shadow:     0 -1px #242424;
      padding-top:     8px;
      padding-bottom:  8px;
      font-size:       12px;
      text-align:      left;

      width:           120px;
      text-align:      center;
      position:        relative;

      border-radius:   4px 0 0 4px;
      margin:          0;

      @include media-breakpoint-down(md) {
        position:  absolute;
        left:      50%;
        transform: translateX(-50%);
        top:       0;
        width:     100%;
      }

      &:hover {
        text-decoration: none;
        background:      linear-gradient(to bottom, #5d5d5d 0%, #434343 50%, #383838 51%, #545454 100%);
        border-width:    0;
      }
    }

    .navbar-nav {
      .nav-item {
        border-width: 0 0 0 1px;
        border-style: solid;
        border-color: #515151;

        &:last-child {
          border-radius: 0 4px 4px 0;
        }

        &:hover, &:focus {
          text-decoration: none;
          background:      linear-gradient(to bottom, #5d5d5d 0%, #434343 50%, #383838 51%, #545454 100%);
          border-width:    0;
        }

        .nav-link {
          color:         white;
          text-decoration: none;
          text-align:      center;
          text-shadow:     0 -1px #242424;
          padding-top:     8px;
          padding-bottom:  8px;
          font-size:       12px;
          text-align:      left;

          width:           120px;
          text-align:      center;
          position:        relative;

          @include media-breakpoint-down(md) {
            width: 100%;
          }

          &:before {
            border-width: 0 0 0 1px;
            border-style: solid;
            border-color: #858685;

            position: absolute;
            z-index: 1;
            top:     0;
            left:    0;
            right:   0;
            content: ' ';
            bottom:  0;
          }
        }
      }
    }

    .navbar-toggler {
      border:  none;
      z-index: 1;

      &:focus {
        box-shadow: none;
      }
    }
  }

  @include media-breakpoint-down(md) {
    position: fixed;
    top:      0;
    right:    0;
    left:     0;
    z-index:  1030;
    border-radius: 0;

    .container-fluid {
      .navbar-brand {
        border-radius: 0;
      }

      .navbar-nav {
        .nav-item {
          border-radius: 0;
        }
      }
    }
  }
}