.carousel {
  margin-top: -100px;

  .carousel-indicators {
    [data-bs-target] {
      background-color: $white !important;
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    filter: none !important;
  }

  .carousel-inner {
    .carousel-item {
      max-height: max(60vh, 500px);

      .carousel-caption {
        color: $white;
      }
    }
  }
}

.marketing {
  .row {
    --bs-gutter-x: 0;
    .img-fluid {
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }
}